import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-animation--fade-in'
    }, {
      id: 'utilities-animation--fade-out'
    }, {
      id: 'utilities-animation--fade-up'
    }, {
      id: 'utilities-animation--fade-down'
    }, {
      id: 'utilities-animation--scale-in'
    }, {
      id: 'utilities-animation--grow-x'
    }, {
      id: 'utilities-animation--pulse'
    }, {
      id: 'utilities-animation--hover'
    }, {
      id: 'utilities-animation--rotate'
    }]} mdxType="StorybookEmbed" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Animation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fade in`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-fade-in`}</inlineCode>{` class is used to fade in an element on the page. This will run once when the element is revealed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fade out`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-fade-out`}</inlineCode>{` class is used to fade out an element on the page. This will run once when the element is revealed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fade up`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-fade-up`}</inlineCode>{` class is used to reveal an element on the page by sliding it up from below the fold. You should use this in a container with `}<inlineCode parentName="td">{`overflow: hidden;`}</inlineCode>{` or on the bottom of the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fade down`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-fade-down`}</inlineCode>{` class is used to slide an element down hiding it. You should use this in a container with `}<inlineCode parentName="td">{`overflow: hidden;`}</inlineCode>{` or on the bottom of the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale in`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-scale-in`}</inlineCode>{` class will scale the element in. This is useful on menus when you want them to appear more friendly.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Grow x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-grow-x`}</inlineCode>{` class will grow an element width from 0-:100: real quick.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pulse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-pulse`}</inlineCode>{` class will pulse an element infinitely.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hover`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-hover-grow`}</inlineCode>{` class will increase the scale of the element upon hover.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rotate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`.anim-rotate`}</inlineCode>{` class will rotate the element indefinitely around the coordinate specified by `}<inlineCode parentName="td">{`transform-origin`}</inlineCode>{`. Most elements have a default of `}<inlineCode parentName="td">{`transform-origin: 50% 50%`}</inlineCode>{` and will rotate around the center.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      